/**
 * @description Componente de notificaciones flotantes
 */

import { MdCheckCircle, MdInfo, MdWarning, MdError } from "react-icons/md";

const iconType = {
  success: <MdCheckCircle size={24} className="text-success" />,
  info: <MdInfo size={24} className="text-info" />,
  warning: <MdWarning size={24} className="text-warning" />,
  error: <MdError size={24} className="text-red-800" />,
};

const Notification = ({
  toast,
  instance,
  type = "info",
  title,
  description,
}) => {
  return (
    <div
      className={`${
        instance?.visible ? "animate-enter" : "animate-leave"
      } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex items-center ring-1 ring-black ring-opacity-5 p-4`}
    >
      <div className="w-full flex flex-row items-start">
        {iconType[type]}
        <div className="ml-2 mr-1 flex flex-col">
          <span className="text-black text-base font-medium">{title}</span>
          <span className="text-black text-opacity-75 text-sm font-medium">
            {description}
          </span>
        </div>
      </div>
      <div className="border-l border-gray-200">
        <button
          onClick={() => toast?.dismiss(instance?.id)}
          className="w-full border border-transparent rounded-none rounded-r-lg flex items-center justify-center text-sm font-semibold text-black pl-2 py-2 focus:outline-none focus:ring-2 focus:ring-info"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Notification;
