/**
 * @description Component SubHeader para páginas internas
 */

import PropTypes from "prop-types";
import ImgSubHeader from "assets/images/carousel/home-slide1.jpg";

const SubHeader = ({ title }) => {
  return (
    <div
      className="w-full flex justify-center bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${ImgSubHeader})` }}
    >
      <div className="container flex justify-center text-center text-white font-bold py-12 px-8 text-2xl lg:text-3xl">
        {title}
      </div>
    </div>
  );
};

SubHeader.propTypes = {
  tile: PropTypes.string,
};

export default SubHeader;
