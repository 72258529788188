/**
 * @description Formulario de reservación de la página de inicio
 */

import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import InputSelect from "react-select";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { FiCalendar, FiUser } from "react-icons/fi";
import { RiHotelLine, RiSearch2Line } from "react-icons/ri";
import {
  transferTypes,
  changeTransferType,
  changeArrivalDate,
  changeDepatureDate,
  changeAdults,
  changeChildren,
  changeHotel,
  changeSummaryArrival,
  changeSummaryDepature,
} from "states/reducers/booking.slice";
import { serviceGetHotels } from "services/hotels";
import Notification from "components/Notifications";
import "react-datepicker/dist/react-datepicker.css";

const FormBooking = () => {
  // Estados locales
  const [hotels, setHotels] = useState([
    { label: "Select a Hotel", value: null },
  ]);

  const { t } = useTranslation();

  // Estado global
  const dispatch = useDispatch();
  const { transferType, arrivalDate, depatureDate, adults, children, hotel } =
    useSelector((state) => state.booking);

  const navigation = useNavigate();

  // Obtener la lista de hoteles desde el api
  useEffect(() => {
    const getHotels = async () => {
      try {
        const data = await serviceGetHotels();
        setHotels(data);
      } catch (error) {
        toast.custom(
          (instance) => (
            <Notification
              type="error"
              toast={toast}
              instance={instance}
              title="Something went wrong"
              description="It was not possible to get the hotels"
            />
          ),
          { duration: 4000, position: "top-center" }
        );
        console.warn("Error getting hotels: ", error);
      }
    };
    getHotels();
  }, []);

  // Form on submit
  const handleSubmit = async (event) => {
    event.preventDefault();

    let arrival,
      depature = null;

    // Verificar que un hotel haya sido seleccionado
    if (!hotel.id) {
      toast.custom(
        (instance) => (
          <Notification
            toast={toast}
            instance={instance}
            title="Complete the information"
            description="Please, select a hotel"
          />
        ),
        { duration: 4000, position: "top-center" }
      );
      return false;
    }

    // Servicio redondo o del aeropuerto al hotel marcar el arrivalDate
    if (
      transferType === transferTypes.round ||
      transferType === transferTypes.airhot
    ) {
      arrival = arrivalDate;
      dispatch(changeSummaryArrival(arrival));
    }

    // Servicio redondo o del hotel al aeropuerto marcar el depatureDate
    if (
      transferType === transferTypes.round ||
      transferType === transferTypes.hotair
    ) {
      depature = depatureDate;
      dispatch(changeSummaryDepature(depature));
    }

    const payload = {
      hotel,
      adults,
      children,
      transferType,
      arrivalDate: arrival,
      depatureDate: depature,
    };

    // Enviar los datos a la página de /pick
    navigation("/pick", {
      state: payload,
    });
  };

  return (
    <form
      className="w-full flex flex-row justify-center relative -top-[4.5rem]"
      onSubmit={handleSubmit}
    >
      <div className="container flex flex-col justify-center items-center">
        <div className="w-full flex flex-row">
          <button
            type="button"
            onClick={() => dispatch(changeTransferType(transferTypes.round))}
            className={`flex-1 md:flex-initial text-xs md:text-base rounded-tl-2xl p-3 md:p-6 font-semibold hover:bg-secondary hover:text-white focus:bg-secondary focus:text-white ${
              transferType === transferTypes.round
                ? "bg-primary text-white"
                : "bg-white text-black"
            }`}
          >
            {t("RESERVE-FORM.ROUND-TRIP")}
          </button>
          <button
            type="button"
            onClick={() => dispatch(changeTransferType(transferTypes.airhot))}
            className={`flex-1 md:flex-initial text-xs md:text-base p-3 md:p-6 font-semibold hover:bg-secondary hover:text-white focus:bg-secondary focus:text-white ${
              transferType === transferTypes.airhot
                ? "bg-primary text-white"
                : "bg-white text-black"
            }`}
          >
            {t("RESERVE-FORM.AIRPORT-TO-HOTEL")}
          </button>
          <button
            onClick={() => dispatch(changeTransferType(transferTypes.hotair))}
            type="button"
            className={`flex-1 md:flex-initial text-xs md:text-base rounded-tr-2xl p-3 md:p-6 font-semibold hover:bg-secondary hover:text-white focus:bg-secondary focus:text-white ${
              transferType === transferTypes.hotair
                ? "bg-primary text-white"
                : "bg-white text-black"
            }`}
          >
            {t("RESERVE-FORM.HOTEL-TO-AIRPORT")}
          </button>
        </div>
        <div className="w-full 2xl:px-6 py-6 md:py-8 rounded-bl-2xl rounded-br-2xl md:rounded-tr-2xl bg-white shadow-md flex flex-wrap flex-row justify-center items-center gap-x-8 gap-y-4 xl:gap-y-0">
          {transferType !== transferTypes.hotair && (
            <div className="w-64 xl:w-48 border border-gray-400 rounded p-3 flex flex-row items-center">
              <FiCalendar
                aria-hidden
                className="text-gray-500 mr-2"
                size={24}
              />
              <div className="flex flex-col">
                <span className="text-gray-700 text-sm">
                  {t("RESERVE-FORM.ARRIVAL")}
                </span>
                <DatePicker
                  required
                  minDate={new Date()}
                  selected={arrivalDate}
                  showDisabledMonthNavigation
                  placeholderText="Select date"
                  className="text-black font-medium text-sm w-28 cursor-pointer placeholder:text-black"
                  onChange={(date) => dispatch(changeArrivalDate(date))}
                />
              </div>
            </div>
          )}
          {transferType !== transferTypes.airhot && (
            <div className="w-64 xl:w-44 border border-gray-400 rounded p-3 flex flex-row items-center">
              <FiCalendar
                aria-hidden
                className="text-gray-500 mr-2"
                size={24}
              />
              <div className="flex flex-col">
                <span className="text-gray-700 text-sm">
                  {t("RESERVE-FORM.DEPARTURE")}
                </span>
                <DatePicker
                  required
                  minDate={new Date()}
                  selected={depatureDate}
                  showDisabledMonthNavigation
                  placeholderText="Select date"
                  className="text-black font-medium text-sm w-28 cursor-pointer placeholder:text-black"
                  onChange={(date) => dispatch(changeDepatureDate(date))}
                />
              </div>
            </div>
          )}

          <div className="w-64 border border-gray-400 rounded p-3 flex flex-row items-center">
            <RiHotelLine aria-hidden className="text-gray-500 mr-2" size={24} />
            <div className="flex flex-col">
              <span className="text-gray-700 text-sm">
                {t("RESERVE-FORM.HOTEL")}
              </span>
              <InputSelect
                required
                placeholder="Select a hotel"
                className="text-black font-medium text-sm"
                classNamePrefix="input__react__select"
                options={hotels}
                onChange={(value) =>
                  dispatch(changeHotel({ id: value.value, name: value.label }))
                }
              />
            </div>
          </div>
          <div className="w-64 xl:w-44 border border-gray-400 rounded p-3 flex flex-row items-center h-[4.375rem]">
            <FiUser aria-hidden className="text-gray-500 mr-2" size={24} />
            <div className="flex flex-col">
              <label className="text-gray-700 text-sm" htmlFor="adults">
                {t("RESERVE-FORM.ADULTS")}
              </label>
              <input
                type="number"
                id="adults"
                name="adults"
                className="text-black font-medium text-sm w-28"
                value={adults}
                onChange={(current) =>
                  dispatch(changeAdults(Number(current.currentTarget.value)))
                }
                placeholder="Required"
                required
                min={1}
                max={16}
              />
            </div>
          </div>
          <div className="w-64 xl:w-44 border border-gray-400 rounded p-3 flex flex-row items-center h-[4.375rem]">
            <FiUser aria-hidden className="text-gray-500 mr-2" size={24} />
            <div className="flex flex-col">
              <label className="text-gray-700 text-sm" htmlFor="children">
                {t("RESERVE-FORM.CHILDRENS")}
              </label>
              <input
                type="number"
                id="children"
                name="children"
                className="text-black font-medium text-sm w-28"
                value={children}
                onChange={(current) =>
                  dispatch(changeChildren(Number(current.currentTarget.value)))
                }
                placeholder="Optional"
                required
                min={0}
                max={10}
              />
            </div>
          </div>
          <button
            type="submit"
            className="bg-primary text-white font-semibold text-center w-64 xl:w-auto py-4 px-12 2xl:px-8 rounded flex items-center justify-center h-[4.375rem] xl:mt-6 2xl:mt-0 hover:bg-secondary ease-in-out duration-200 transition-colors focus:ring"
          >
            <RiSearch2Line aria-hidden className="text-white mr-2" size={18} />
            {t("RESERVE-FORM.SEARCH")}
          </button>
        </div>
      </div>
    </form>
  );
};

export default FormBooking;
