/**
 * @description About page
 */

import { useTranslation } from "react-i18next";
import { FaPhone, FaEnvelope } from "react-icons/fa";
import LayoutBase from "components/Layouts/Base";
import SubHeader from "components/Header/SubHeader";

const PageAbout = () => {
  const { t } = useTranslation();

  return (
    <LayoutBase title={t("ABOUTUS.METATITLE")}>
      <SubHeader title={t("ABOUTUS.TITLE")} />
      <div className="w-full flex justify-center bg-[#F5F4F4] py-8 lg:py-16">
        <div className="container flex flex-row">
          <div className="w-full flex flex-col">
            <h2 className="text-gray-600 font-semibold text-4xl">
              Best Line Cancún
            </h2>
            {t("ABOUTUS.DESCRIPTION")
              .split("/n")
              .map((line) => (
                <p className="text-justify my-2">{line}</p>
              ))}
            <h3 className="text-gray-600 font-medium text-2xl my-4">
              {t("ABOUTUS.CONTACT US")}
            </h3>
            <div className="flex flex-col gap-y-4">
              <div className="text-gray-600 font-medium flex flex-row items-center">
                <FaPhone className="text-primary w-4 h-4" />
                <a href="tel:+52 (998) 192 0397" className="font-small">
                  &nbsp; +52 (998) 192 0397
                </a>
              </div>
              <div className="text-gray-600 font-medium flex flex-row items-center">
                <FaPhone className="text-primary w-4 h-4" />
                <a href="tel:+52 (998) 293 1705" className="font-small">
                  &nbsp; +52 (998) 293 1705
                </a>
              </div>
            </div>
            <div className="flex flex-col mt-4 gap-y-4">
              <div className="text-gray-600 font-medium flex flex-row items-center">
                <FaEnvelope className="text-primary w-4 h-4" />
                <a href="mailto:info@bestlinecancun.com" className="font-small">
                  &nbsp; info@bestlinecancun.com
                </a>
              </div>
              <div className="text-gray-600 font-medium flex flex-row items-center">
                <FaEnvelope className="text-primary w-4 h-4" />
                <a
                  href="mailto:reservations@bestlinecancun.com"
                  className="font-small"
                >
                  &nbsp; reservaciones@bestlinecancun.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutBase>
  );
};

export default PageAbout;
