/**
 * @description Punto de entrada de React
 */

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Cargar las fuentes locales
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";

// Cargar la configuración de internalización
import "./i18n";

// Cargar la hoja de estilos
import "./styles/index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

/**
 * @description Si desea comenzar a medir el rendimiento en su aplicación, pase una función para registrar los resultados (por ejemplo: reportWebVitals(console.log)) o enviar a un punto final de análisis.
 * Más información: https://bit.ly/CRA-vitals
 */
reportWebVitals();
