/**
 * @description Página de /pick
 */

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineBusAlert, MdOutlineHome } from "react-icons/md";
import { CgSpinner } from "react-icons/cg";
import LayoutBase from "components/Layouts/Base";
import SubHeader from "components/Header/SubHeader";
import CardServiceType from "components/Cards/ServiceType";
import CardSummary from "components/Cards/Summary";
import { serviceGetHotel } from "services/hotels";
import { changeZone } from "states/reducers/booking.slice";
import { transferTypes } from "states/reducers/booking.slice";

// Imágenes
import ImgCollective from "assets/images/vehicles/collective.jpg";
import ImgPrivate from "assets/images/vehicles/private.jpg";
import ImgVIP from "assets/images/vehicles/vip.jpg";

const PagePick = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { state: bookingData } = useLocation();
  const dispatch = useDispatch();
  const { hotel, transferType } = useSelector((state) => state.booking);

  useEffect(() => {
    const getHotel = async () => {
      try {
        setLoading(true);
        const data = await serviceGetHotel(bookingData?.hotel?.id);
        dispatch(changeZone(data?.idZona));
      } catch (error) {
        console.error("Error getting the hotel: ", error);
      } finally {
        setLoading(false);
      }
    };

    bookingData && getHotel();
  }, [bookingData, dispatch]);

  return (
    <LayoutBase title={t("BOOKING-TRANSFER.METATITLE")}>
      <SubHeader title={t("BOOKING-TRANSFER.TITLE")} />
      <div className="w-full flex justify-center bg-[#F5F4F4] py-8 lg:py-16">
        <div className="container">
          {/** Contenido de carga mientras se consulta el idZona */}
          {loading && (
            <div className="w-full flex flex-col justify-center items-center">
              <CgSpinner size={64} className="text-primary animate-spin mb-8" />
              <span className="text-black font-semibold text-xl">
                Loading / Cargando
              </span>
            </div>
          )}
          {/** Mostrar en caso de no existir data */}
          {!bookingData && (
            <div className="w-full flex flex-col justify-center items-center text-center">
              <MdOutlineBusAlert
                className="text-black text-opacity-70 mb-8"
                size={64}
              />
              <span className="text-black font-semibold text-xl">
                {t("ERRORS.BOOKING-EMPTY")}
              </span>
              <Link
                to="/"
                className="bg-primary rounded text-white font-semibold py-4 px-8 flex items-center my-8"
              >
                <MdOutlineHome size={20} className="mr-2" />
                {t("ERRORS.GOTO-HOME")}
              </Link>
            </div>
          )}
          {/** Contenido de la página */}
          {bookingData && !loading && (
            <div className="w-full flex flex-col items-center lg:flex-row lg:justify-between gap-2">
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                {hotel?.idZone &&
                  hotel?.idZone < 23 &&
                  transferType !== transferTypes.round && (
                    <CardServiceType
                      image={ImgCollective}
                      name={t("BOOKING-TRANSFER.COLLECTIVE")}
                      passengers={15}
                      bags={10}
                      id={1}
                    />
                  )}
                <CardServiceType
                  image={ImgPrivate}
                  name={t("BOOKING-TRANSFER.PRIVATE")}
                  passengers={8}
                  bags={10}
                  id={2}
                />
                <CardServiceType
                  image={ImgVIP}
                  name={t("BOOKING-TRANSFER.VIP")}
                  passengers={8}
                  bags={10}
                  id={3}
                />
              </div>
              {/** Tarjeta flotante del resumen */}
              <CardSummary />
            </div>
          )}
        </div>
      </div>
    </LayoutBase>
  );
};

export default PagePick;
