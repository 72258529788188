/**
 * @description Página de confirmación de la reservación
 */

import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FcCheckmark } from "react-icons/fc";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MdOutlineBusAlert, MdOutlineHome } from "react-icons/md";
import LayoutBase from "components/Layouts/Base";
import SubHeader from "components/Header/SubHeader";
import { transferTypes } from "states/reducers/booking.slice";
import { MdOutlineDownload } from "react-icons/md";

const PageConfirmation = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const { transferType, summary, hotel, adults, children } = useSelector(
    (state) => state.booking
  );

  return (
    <LayoutBase title={t("CONFIRMATION.TITLE")}>
      <SubHeader title={t("CONFIRMATION.TITLE")} />
      <div className="w-full flex justify-center bg-[#F5F4F4] py-8 lg:py-16">
        <div className="container">
          {!hotel?.id ? (
            <div className="w-full flex flex-col justify-center items-center text-center">
              <MdOutlineBusAlert
                className="text-black text-opacity-70 mb-8"
                size={64}
              />
              <span className="text-black font-semibold text-xl">
                {t("ERRORS.BOOKING-EMPTY")}
              </span>
              <Link
                to="/"
                className="bg-primary rounded text-white font-semibold py-4 px-8 flex items-center my-8"
              >
                <MdOutlineHome size={20} className="mr-2" />
                {t("ERRORS.GOTO-HOME")}
              </Link>
            </div>
          ) : (
            <div className="w-full flex flex-col justify-center items-center text-center">
              <div
                className="w-24 h-24 rounded-full flex justify-center items-center bg-white shadow"
                aria-hidden
              >
                <FcCheckmark size={64} />
              </div>
              <h2 className="text-black text-2xl mt-8 mb-4 font-semibold">
                {t("CONFIRMATION.SUBTITLE")}
              </h2>
              <span className="mb-16 font-medium">
                {t("CONFIRMATION.INFO")} {state?.email}
              </span>
              <div className="w-full flex justify-center items-center">
                <div className="w-full sm:w-2/3 lg:w-1/2 flex flex-col border-2 border-dashed p-4 border-black rounded-lg">
                  <div className="w-full flex justify-between mb-4 border-b border-b-black border-opacity-20 pb-4">
                    <span className="font-medium text-sm text-black text-opacity-80">
                      {t("BOOKING-TRANSFER.TRANSFER-TYPE")}:
                    </span>
                    <span className="font-semibold text-sm text-black text-opacity-80">
                      {transferType === transferTypes.round
                        ? `${t("RESERVE-FORM.ROUND-TRIP")}`
                        : transferType === transferTypes.airhot
                        ? `${t("RESERVE-FORM.AIRPORT-TO-HOTEL")}`
                        : `${t("RESERVE-FORM.HOTEL-TO-AIRPORT")}`}
                    </span>
                  </div>
                  {summary.arrivalDate && (
                    <div className="w-full flex justify-between mb-4 border-b border-b-black border-opacity-20 pb-4">
                      <span className="font-medium text-sm text-black text-opacity-80">
                        {t("BOOKING-TRANSFER.ARRIVAL")}:
                      </span>
                      <span className="font-semibold text-sm text-black text-opacity-80">
                        {dayjs(summary.arrivalDate).format("DD/MM/YYYY")}
                      </span>
                    </div>
                  )}
                  {summary.depatureDate && (
                    <div className="w-full flex justify-between mb-4 border-b border-b-black border-opacity-20 pb-4">
                      <span className="font-medium text-sm text-black text-opacity-80">
                        {t("BOOKING-TRANSFER.DEPARTURE")}:
                      </span>
                      <span className="font-semibold text-sm text-black text-opacity-80">
                        {dayjs(summary.depatureDate).format("DD/MM/YYYY")}
                      </span>
                    </div>
                  )}
                  <div className="w-full flex justify-between mb-4 border-b border-b-black border-opacity-20 pb-4">
                    <span className="font-medium text-sm text-black text-opacity-80">
                      {t("BOOKING-TRANSFER.FROM")}:
                    </span>
                    <span className="font-semibold text-sm text-black text-opacity-80">
                      {transferType === transferTypes.hotair
                        ? hotel.name
                        : "Cancún International Airport"}
                    </span>
                  </div>
                  <div className="w-full flex justify-between mb-4 border-b border-b-black border-opacity-20 pb-4">
                    <span className="font-medium text-sm text-black text-opacity-80">
                      {t("BOOKING-TRANSFER.TO")}:
                    </span>
                    <span className="font-semibold text-sm text-black text-opacity-80">
                      {transferType === transferTypes.hotair
                        ? "Cancún International Airport"
                        : hotel.name}
                    </span>
                  </div>
                  <div className="w-full flex justify-between mb-4 border-b border-b-black border-opacity-20 pb-4">
                    <span className="font-medium text-sm text-black text-opacity-80">
                      {t("BOOKING-TRANSFER.PASSAGERS")}:
                    </span>
                    <span className="font-semibold text-sm text-black text-opacity-80">
                      {adults} {t("BOOKING-TRANSFER.ADULTS")}
                      {children > 0 &&
                        `, ${children} ${t("BOOKING-TRANSFER.CHILDRENS")}`}
                    </span>
                  </div>
                  <div className="w-full flex justify-between mb-4 border-b border-b-black border-opacity-20 pb-4">
                    <span className="font-medium text-sm text-black text-opacity-80">
                      {t("BOOKING-TRANSFER.SERVICE-TYPE")}:
                    </span>
                    <span className="font-semibold text-sm text-black text-opacity-80">
                      {summary?.serviceType === 1
                        ? t("BOOKING-TRANSFER.COLLECTIVE")
                        : summary?.serviceType === 2
                        ? t("BOOKING-TRANSFER.PRIVATE")
                        : t("BOOKING-TRANSFER.VIP")}
                    </span>
                  </div>
                  <div className="w-full flex justify-between mb-4 border-b border-b-black border-opacity-20 pb-4">
                    <span className="font-medium text-sm text-black text-opacity-80">
                      {t("BOOKING-TRANSFER-CHECKOUT.AIRLINE")}
                    </span>
                    <span className="font-semibold text-sm text-black text-opacity-80">
                      {state?.aerolinea}
                    </span>
                  </div>
                  <div className="w-full flex justify-between mb-4 border-b border-b-black border-opacity-20 pb-4">
                    <span className="font-medium text-sm text-black text-opacity-80">
                      {t("BOOKING-TRANSFER-CHECKOUT.FLIGHTNUMBER")}
                    </span>
                    <span className="font-semibold text-sm text-black text-opacity-80">
                      {state?.vuelo}
                    </span>
                  </div>
                  <div className="w-full flex justify-between mb-4 border-b border-b-black border-opacity-20 pb-4">
                    <span className="font-medium text-sm text-black text-opacity-80">
                      {t("BOOKING-TRANSFER-CHECKOUT.FIRSTNAME")}
                    </span>
                    <span className="font-semibold text-sm text-black text-opacity-80">
                      {state?.nombre}
                    </span>
                  </div>
                  <div className="w-full flex justify-between mb-4 border-b border-b-black border-opacity-20 pb-4">
                    <span className="font-medium text-sm text-black text-opacity-80">
                      {t("BOOKING-TRANSFER-CHECKOUT.PHONENUMBER")}
                    </span>
                    <span className="font-semibold text-sm text-black text-opacity-80">
                      {state?.telefono}
                    </span>
                  </div>
                  <div className="w-full flex justify-between mb-4 border-b border-b-black border-opacity-20 pb-4">
                    <span className="font-medium text-sm text-black text-opacity-80">
                      Email:
                    </span>
                    <span className="font-semibold text-sm text-black text-opacity-80">
                      {state?.email}
                    </span>
                  </div>
                  <div className="w-full flex justify-between pb-2">
                    <span className="font-semibold text-2xl text-black text-opacity-80">
                      Total:
                    </span>
                    <span className="font-bold text-2xl text-primary">
                      {summary?.price ? `${summary?.price} USD` : "Pending"}
                    </span>
                  </div>
                </div>
              </div>
              <button
              className="flex justify-center items-center bg-primary rounded text-white font-semibold py-4 px-8 flex items-center my-8"
              >
                <MdOutlineDownload size={20} className="mr-2" />
                {t("PDF.DOWNLOAD")}
              </button>
            </div>
          )}
        </div>
      </div>
      
    </LayoutBase>
  );
};

export default PageConfirmation;
