/**
 * @description Call to action para registro de correos
 */

import { useTranslation } from "react-i18next";

const CTAEmail = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex justify-center bg-secondary text-white">
      <div className="container flex flex-col xl:flex-row justify-between items-center py-12 gap-y-8">
        <span className="text-white font-semibold md:text-xl xl:text-2xl text-center md:text-left">
          {t("CTA-EMAIL.TITLE")}
        </span>
        <form className="flex items-center">
          <input
            required
            name="email"
            type="email"
            minLength={8}
            maxLength={128}
            className="p-2 md:p-4 w-64 sm:w-80 text-black text-sm sm:text-base font-medium rounded rounded-tr-none rounded-br-none h-[3.5rem] border-none"
            placeholder="Email address"
          />
          <button
            type="submit"
            className="bg-primary text-white h-[3.5rem] px-2 sm:px-8 rounded rounded-tl-none rounded-br-none font-semibold text-sm sm:text-base"
          >
            {t("CTA-EMAIL.BUTTON")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CTAEmail;
