/**
 * @description Servicio web de reservaciones
 */

import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export const servicePostReservation = async (payload) => {
  try {
    const { data } = await axios.post(`${baseURL}/reservation/create`, payload);
    return data;
  } catch (error) {
    throw error;
  }
};
