/**
 * @description Componente de mensaje de carga durante la carga del idioma
 */

import Img from "react-cool-img";
import ImgLogo from "assets/images/logo.jpg";

const SuspenseFallBack = () => {
  return (
    <div className="w-full min-h-screen flex flex-col justify-center items-center">
      <Img src={ImgLogo} alt="Bestline Cancún Logo" />
      <div className="flex flex-col my-8">
        <span className="text-center mb-4 font-semibold text-xl text-black">
          Cargando / Loading
        </span>
      </div>
    </div>
  );
};

export default SuspenseFallBack;
