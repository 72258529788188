/**
 * @description Servicio web de hoteles
 */

import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export const serviceGetHotels = async () => {
  try {
    const { data } = await axios.get(`${baseURL}/hotels/all`);
    return data?.hotels;
  } catch (error) {
    throw error;
  }
};

export const serviceGetHotel = async (idHotel) => {
  try {
    const { data } = await axios.get(`${baseURL}/hotels/${idHotel}`);
    return data.hotel;
  } catch (error) {
    throw error;
  }
};
