/**
 * @description Sección de favorites de la página de inicio
 */

import { useTranslation } from "react-i18next";
import CardDestination from "components/Cards/Destination";

// Images
import ImgCancun from "assets/images/destinations/cancun.jpg";
import ImgPlaya from "assets/images/destinations/playa.jpg";
import ImgAkumal from "assets/images/destinations/akumal.jpg";

const Favorites = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex justify-center bg-white pb-2 md:pb-6">
      <div className="container flex flex-col items-center">
        <span className="text-center font-medium text-black text-opacity-60">
          {t("FAVORITES.SUBTITLE")}
        </span>
        <h2 className="text-center text-black text-4xl font-bold mt-2 mb-8 md:mb-16">
          {t("FAVORITES.FIRST_TITLE")}
        </h2>
        <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-6 mb-8">
          <CardDestination
            image={ImgCancun}
            name="Cancun"
            className="col-span-1"
          />
          <CardDestination
            image={ImgPlaya}
            name="Playa Del Carmen"
            className="col-span-1"
          />
          <CardDestination
            image={ImgAkumal}
            name="Akumal"
            className="col-span-1"
          />
        </div>
      </div>
    </div>
  );
};

export default Favorites;
