/**
 * @description Carousel de la página de vehículos
 */

import { useState } from "react";
import Img from "react-cool-img";
import { useTranslation } from "react-i18next";
import { useKeenSlider } from "keen-slider/react";
import { IoChevronForwardOutline, IoChevronBackOutline } from "react-icons/io5";

// Imágenes
import ImgSuburban from "assets/images/vehicles/suburban.jpg";
import ImgSprinter from "assets/images/vehicles/sprinter.jpg";
import ImgToyota from "assets/images/vehicles/toyota.jpg";
import ImgVW from "assets/images/vehicles/vw.jpg";
import ImgHyundai from "assets/images/vehicles/hyundai.jpg";
import ImgAutobus from "assets/images/vehicles/autobus.jpg";
import ImgPlaceHolder from "assets/placeholders/placeholder-white.jpg";

import "keen-slider/keen-slider.min.css";

const CarouselVehicles = () => {
  const { t } = useTranslation();

  // Keen Slider config
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
    breakpoints: {
      "(min-width: 1024px)": {
        slides: { perView: 4, spacing: 16 },
      },
      "(max-width: 1023px)": {
        slides: { perView: 2, spacing: 8 },
      },
      "(max-width: 639px)": {
        slides: { perView: 1, spacing: 8 },
      },
    },
    slides: { perView: 1 },
  });

  return (
    <div className="w-full flex justify-center bg-white py-8 md:py-16">
      <div className="container flex flex-col">
        <span className="text-center font-medium text-black text-opacity-60">
          {t("VEHICLES.SUBTITLE")}
        </span>
        <h2 className="text-center text-black text-4xl font-bold mt-2 mb-8 md:mb-16">
          {t("VEHICLES.TITLE")}
        </h2>
        <div className="w-full relative">
          <div ref={sliderRef} className="keen-slider">
            <div className="keen-slider__slide w-80 text-center flex flex-col items-center justify-center border-2 border-[#e8d136]">
              <Img
                src={ImgSuburban}
                alt="Suburban vehicle"
                className="w-full md:w-96"
                placeholder={ImgPlaceHolder}
              />
              <span className="text-lg font-semibold text-black py-4 text-opacity-70">
                V.I.P. Suburban
              </span>
            </div>
            <div className="keen-slider__slide w-80 text-center flex flex-col items-center justify-center border-2 border-[#e8d136]">
              <Img
                src={ImgSprinter}
                alt="Sprinter vehicle"
                className="w-full md:w-96"
                placeholder={ImgPlaceHolder}
              />
              <span className="text-lg font-semibold text-black py-4 text-opacity-70">
                Sprinter
              </span>
            </div>
            <div className="keen-slider__slide w-80 text-center flex flex-col items-center justify-center border-2 border-[#e8d136]">
              <Img
                src={ImgToyota}
                alt="Toyota Hiace vehicle"
                className="w-full md:w-96"
                placeholder={ImgPlaceHolder}
              />
              <span className="text-lg font-semibold text-black py-4 text-opacity-70">
                Toyota Hiace
              </span>
            </div>
            <div className="keen-slider__slide w-80 text-center flex flex-col items-center justify-center border-2 border-[#e8d136]">
              <Img
                src={ImgVW}
                alt="VW Transporter vehicle"
                className="w-full md:w-96"
                placeholder={ImgPlaceHolder}
              />
              <span className="text-lg font-semibold text-black py-4 text-opacity-70">
                VW Transporter
              </span>
            </div>
            <div className="keen-slider__slide w-80 text-center flex flex-col items-center justify-center border-2 border-[#e8d136]">
              <Img
                src={ImgHyundai}
                alt="Hyundai vehicle"
                className="w-full md:w-96"
                placeholder={ImgPlaceHolder}
              />
              <span className="text-lg font-semibold text-black py-4 text-opacity-70">
                Hyundai
              </span>
            </div>
            <div className="keen-slider__slide w-80 text-center flex flex-col items-center justify-center border-2 border-[#e8d136]">
              <Img
                src={ImgAutobus}
                alt="Autobus vehicle"
                className="w-full md:w-96"
                placeholder={ImgPlaceHolder}
              />
              <span className="text-lg font-semibold text-black py-4 text-opacity-70">
                Autobus
              </span>
            </div>
          </div>
          {loaded && instanceRef.current && (
            <>
              <button
                className="absolute top-[47.5%] left-2 md:left-8 bg-white rounded-full bg-opacity-50 p-1 md:p-2 flex justify-center items-center disabled:cursor-not-allowed"
                disabled={currentSlide === 0}
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
              >
                <IoChevronBackOutline size={32} />
              </button>

              <button
                className="absolute top-[47.5%] right-2 md:right-8 bg-white rounded-full bg-opacity-50 p-1 md:p-2 flex justify-center items-center disabled:cursor-not-allowed"
                disabled={
                  currentSlide ===
                  instanceRef.current.track.details.slides.length - 1
                }
                onClick={(e) =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
              >
                <IoChevronForwardOutline className="text-black" size={32} />
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CarouselVehicles;
