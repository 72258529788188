// layout.jsx

import React from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';

const Layout = ({ title, description, keywords, children }) => {
  return (
    <div>
      <head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </head>
      < Header />
      <main>
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
