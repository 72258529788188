/**
 * @description Tarjeta de selección del tipo de transporte
 */

import { useState } from "react";
import Img from "react-cool-img";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { RiCheckFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BiLoaderAlt } from "react-icons/bi";
import Notification from "components/Notifications";
import {
  changeService,
  transferTypes,
  changePrice,
  changeTarifa,
} from "states/reducers/booking.slice";
import { servicePostRates } from "services/rates";

// Imágenes
import ImgSeats from "assets/images/vehicles/seats.png";
import ImgBags from "assets/images/vehicles/bags.png";

const CardServiceType = ({ id, image, name, passengers, bags }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { summary, adults, children, transferType, hotel } = useSelector(
    (state) => state.booking
  );

  const calculateRate = async (serviceType) => {
    let min = 0;
    let max = 0;
    const totalPassengers = Number(adults) + Number(children);

    // Asignar la capacidad mínima y máxima en base al total de pasajeros
    if (serviceType === 2 || serviceType === 3) {
      if (totalPassengers > 8) {
        min = 9;
        max = 14;
      } else if (totalPassengers > 4) {
        min = 5;
        max = 8;
      } else {
        min = 1;
        max = 4;
      }
    }

    if (serviceType === 1) {
      min = 0;
      max = 0;
    }

    const payload = {
      origen: transferType === transferTypes.hotair ? hotel?.idZone : 1,
      destino: transferType === transferTypes.hotair ? 1 : hotel?.idZone,
      tipo: transferType === transferTypes.hotair ? 2 : transferType,
      vehiculo: serviceType,
      min,
      max,
    };

    setLoading(true);
    try {
      const response = await servicePostRates(payload);
      if (serviceType === 1) {
        const total = Number(response?.precio) * totalPassengers;
        dispatch(changePrice(total));
      } else {
        dispatch(changePrice(Number(response?.precio)));
      }
      dispatch(changeTarifa(response?.idTarifa));
    } catch (error) {
      toast.custom(
        (instance) => (
          <Notification
            type="error"
            toast={toast}
            instance={instance}
            title="Something went wrong"
            description="It was not possible to get rate"
          />
        ),
        { duration: 4000, position: "top-center" }
      );
      console.info("Error getting rate: ", error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Llamar a la función calculadora de tarifas
   */
  const handleClick = () => {
    dispatch(changeService(id));
    calculateRate(id);
  };

  return (
    <div
      className={`w flex flex-col self-start border-4 hover:border-info rounded ${
        summary?.serviceType === id ? "border-warning" : "border-gray-200"
      }`}
    >
      <Img
        className="w-full h-[13rem] object-cover object-center"
        src={image}
        alt={name}
      />
      <div className="w-full bg-white p-4 flex flex-col">
        <span className="text-center text-black text-opacity-75 font-semibold text-xl">
          {name}
        </span>
        <div className="w-full flex flex-row items-center gap-4 my-4">
          <Img src={ImgSeats} alt="Seat icon" className="w-8 h-8" />
          <div className="flex flex-col">
            <span className="text-sm font-medium text-black text-opacity-75">
              {t("BOOKING-TRANSFER.IDEAL")}:
            </span>
            <span className="text-sm font-semibold text-black text-opacity-75">
              1 - {passengers} {t("BOOKING-TRANSFER.PASSAGERS")}
            </span>
          </div>
        </div>
        <div className="w-full flex flex-row items-center gap-4 my-4">
          <Img src={ImgBags} alt="Bag icon" className="w-8 h-8" />
          <div className="flex flex-col">
            <span className="text-sm font-medium text-black text-opacity-75">
              {t("BOOKING-TRANSFER.CAPACITY")}:
            </span>
            <span className="text-sm font-semibold text-black text-opacity-75">
              {t("BOOKING-TRANSFER.UP TO", { bags })}
            </span>
          </div>
        </div>
      </div>
      <button
        disabled={loading}
        className="w-full bg-primary py-2 px-4 text-white font-semibold text-center flex items-center justify-center hover:bg-secondary ease-in-out duration-200 transition-colors focus:ring disabled:cursor-not-allowed"
        onClick={handleClick}
      >
        {loading ? (
          <BiLoaderAlt size={20} className="animate-spin text-white" />
        ) : (
          summary?.serviceType === id && (
            <RiCheckFill className="mr-1" size={20} />
          )
        )}
        {t("BOOKING-TRANSFER.SELECT")}
      </button>
    </div>
  );
};

CardServiceType.propTypes = {
  id: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string,
  passengers: PropTypes.number.isRequired,
  bags: PropTypes.number.isRequired,
};

export default CardServiceType;
