/**
 * @description Configuración del store para Redux Toolkit
 */

import { configureStore } from "@reduxjs/toolkit";

// Importar reducers
import bookingReducer from "./reducers/booking.slice";

const store = configureStore({
  reducer: {
    booking: bookingReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
