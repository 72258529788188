/**
 * @description Ruteador de la aplicación
 */

import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import store from "states/store";

// Cargar todas las rutas como componentes
import PageIndex from "pages";
import PagePick from "pages/Pick";
import PageCheckout from "pages/Checkout";
import PageConfirmation from "pages/Confirmation";
import PageAbout from "pages/About";
import PageTerms from "pages/Terms";
import ScrollTop from "./ScrollTop";
import Page404 from "pages/404/404"

const Router = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollTop>
          <Routes>
            <Route path="/" element={<PageIndex />} />
            <Route path="/pick" element={<PagePick />} />
            <Route path="/checkout" element={<PageCheckout />} />
            <Route path="/confirmation" element={<PageConfirmation />} />
            <Route path="/about" element={<PageAbout />} />
            <Route path="/terms" element={<PageTerms />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </ScrollTop>
      </BrowserRouter>
    </Provider>
  );
};

export default Router;
