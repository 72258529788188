/**
 * @description Sección de nosotros de la página de inicio
 */

import { useTranslation } from "react-i18next";
import { MdOutlineAirportShuttle } from "react-icons/md";
import { TbAward } from "react-icons/tb";
import Img from "react-cool-img";

// Imágenes
import ImgCash from "assets/images/about/cash.png";
import ImgRadar from "assets/images/about/radar.png";
import ImgTime from "assets/images/about/time.png";
import ImgVan from "assets/images/about/van.png";

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex justify-center pt-8 xl:pt-12 xl:pb-12 pb-8 bg-[#F5F4F4]">
      <div className="container flex flex-col xl:flex-row items-center">
        <div className="w-full xl:w-2/5 xl:pr-4">
          <div className="w-full bg-secondary text-white flex flex-col rounded px-6 py-12 xl:py-16">
            <h3 className="font-semibold text-2xl mb-4 flex items-center">
              <MdOutlineAirportShuttle className="text-white mr-2" size={32} />
              Best Line Cancún
            </h3>
            <p>{t("ABOUT.DESCRIPTION")}</p>
            <div className="w-full border border-white my-8"></div>
            <span className="font-medium text-xl mb-4 flex items-center">
              <TbAward className="text-white mr-2" size={24} />
              {t("ABOUT.OUR GUARANTEES")}
            </span>
            <span className="indent-1 mb-1">* {t("ABOUT.GUARANTEE1")}</span>
            <span className="indent-1 mb-1">* {t("ABOUT.GUARANTEE2")}</span>
            <span className="indent-1 mb-1">* {t("ABOUT.GUARANTEE3")}</span>
            <span className="indent-1 mb-1">* {t("ABOUT.GUARANTEE4")}</span>
          </div>
        </div>
        <div className="w-full xl:w-3/5 grid grid-cols-2 xl:pl-4 mt-8 xl:mt-0 gap-4">
          <div className="col-span-2 sm:col-span-1 w-full bg-white">
            <div className="flex flex-col items-center justify-center border rounded py-16 px-4 lg:px-8">
              <Img src={ImgCash} alt="Best price icon" className="w-20 h-20" />
              <h3 className="text-center font-semibold text-opacity-70 text-black my-2 text-lg">
                {t("ABOUT.CARD1TITLE")}
              </h3>
              <p className="text-center text-black text-opacity-75 font-medium text-sm sm:h-[4.5rem] md:h-[3rem]">
                {t("ABOUT.CARD1CONTENT")}
              </p>
            </div>
          </div>
          <div className="col-span-2 sm:col-span-1 w-full bg-white">
            <div className="flex flex-col items-center justify-center border rounded py-16 px-4 lg:px-8">
              <Img src={ImgTime} alt="Low time icon" className="w-20 h-20" />
              <h3 className="text-center font-semibold text-opacity-70 text-black my-2 text-lg">
                {t("ABOUT.CARD2TITLE")}
              </h3>
              <p className="text-center text-black text-opacity-75 font-medium text-sm sm:h-[4.5rem] md:h-[3rem]">
                {t("ABOUT.CARD2CONTENT")}
              </p>
            </div>
          </div>
          <div className="col-span-2 sm:col-span-1 w-full bg-white">
            <div className="flex flex-col items-center justify-center border rounded py-16 px-4 lg:px-8">
              <Img src={ImgVan} alt="Shuttle icon" className="w-20 h-20" />
              <h3 className="text-center font-semibold text-opacity-70 text-black my-2 text-lg">
                {t("ABOUT.CARD3TITLE")}
              </h3>
              <p className="text-center text-black text-opacity-75 font-medium text-sm sm:h-[4.5rem] md:h-[3rem]">
                {t("ABOUT.CARD3CONTENT")}
              </p>
            </div>
          </div>
          <div className="col-span-2 sm:col-span-1 w-full bg-white">
            <div className="flex flex-col items-center justify-center border rounded py-16 px-4 lg:px-8">
              <Img src={ImgRadar} alt="Radar icon" className="w-20 h-20" />
              <h3 className="text-center font-semibold text-opacity-70 text-black my-2 text-lg">
                {t("ABOUT.CARD4TITLE")}
              </h3>
              <p className="text-center text-black text-opacity-75 font-medium text-sm sm:h-[4.5rem] md:h-[3rem]">
                {t("ABOUT.CARD4CONTENT")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
