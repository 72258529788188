/**
 * @description Página "/"
 */

import LayoutBase from "components/Layouts/Base";
import CarouselHome from "components/Home/Carousel/CarouselHome";
import FormBooking from "components/Home/Forms/Booking";
import Favorites from "components/Home/Favorites";
import About from "components/Home/About";
import CarouselVehicles from "components/Home/Carousel/CasouselVehicles";
import CTAEmail from "components/CTA/Email";

const PageIndex = () => {
  return (
    <div className="w-full">
      <LayoutBase>
        <CarouselHome />
        <div className="w-full flex justify-center">
          <FormBooking />
        </div>
        <Favorites />
        <About />
        <CarouselVehicles />
        <CTAEmail />
      </LayoutBase>
    </div>
  );
};

export default PageIndex;
