/**
 * @description Carousel de la página de inicio
 */

import { useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import { useTranslation } from "react-i18next";
import { IoChevronForwardOutline, IoChevronBackOutline } from "react-icons/io5";
import ImgSlide1 from "assets/images/carousel/home-slide1.jpg";
import ImgSlide2 from "assets/images/carousel/home-slide2.jpg";
import ImgSlide3 from "assets/images/carousel/home-slide3.jpg";

import "keen-slider/keen-slider.min.css";

const CarouselHome = () => {
  const { t } = useTranslation();

  // Configuración de Keen Slider
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  return (
    <div className="w-full relative">
      {/** Slides */}
      <div ref={sliderRef} className="keen-slider h-[460px] md:h-[500px]">
        <div
          className="keen-slider__slide w-full bg-no-repeat bg-cover bg-center text-center text-white flex flex-col items-center justify-center"
          style={{ backgroundImage: `url(${ImgSlide1})` }}
        >
          <h1 className="text-2xl md:text-5xl font-bold mb-2">
            {t("BANNER.SLIDE1TITLE")}
          </h1>
          <h2 className="text-2xl md:text-4xl font-semibold mb-2">
            {t("BANNER.SLIDE1SUBTITLE")}
          </h2>
          <h3 className="text-lg md:text-xl font-medium px-4 md:px-0">
            {t("BANNER.SLIDE1CONTENT")}
          </h3>
        </div>
        <div
          className="keen-slider__slide w-full bg-no-repeat bg-cover bg-center text-center text-white flex flex-col items-center justify-center"
          style={{ backgroundImage: `url(${ImgSlide2})` }}
        >
          <h1 className="text-2xl md:text-5xl font-bold mb-2">
            {t("BANNER.SLIDE2TITLE")}
          </h1>
          <h2 className="text-2xl md:text-4xl font-semibold mb-2">
            {t("BANNER.SLIDE2SUBTITLE")}
          </h2>
          <h3 className="text-lg md:text-xl font-medium px-4 md:px-0">
            {t("BANNER.SLIDE2CONTENT")}
          </h3>
        </div>
        <div
          className="keen-slider__slide w-full bg-no-repeat bg-cover bg-center text-center text-white flex flex-col items-center justify-center"
          style={{ backgroundImage: `url(${ImgSlide3})` }}
        >
          <h1 className="text-2xl md:text-5xl font-bold mb-2">
            {t("BANNER.SLIDE3TITLE")}
          </h1>
          <h2 className="text-2xl md:text-4xl font-semibold mb-2">
            {t("BANNER.SLIDE3SUBTITLE")}
          </h2>
          <h3 className="text-lg md:text-xl font-medium px-4 md:px-0">
            {t("BANNER.SLIDE3CONTENT")}
          </h3>
        </div>
      </div>
      {/** Paginación */}
      {loaded && instanceRef.current && (
        <>
          <button
            className="absolute top-[47.5%] left-2 md:left-8 bg-white rounded-full bg-opacity-50 p-1 md:p-2 flex justify-center items-center disabled:cursor-not-allowed"
            disabled={currentSlide === 0}
            onClick={(e) => e.stopPropagation() || instanceRef.current?.prev()}
          >
            <IoChevronBackOutline size={32} />
          </button>

          <button
            className="absolute top-[47.5%] right-2 md:right-8 bg-white rounded-full bg-opacity-50 p-1 md:p-2 flex justify-center items-center disabled:cursor-not-allowed"
            disabled={
              currentSlide ===
              instanceRef.current.track.details.slides.length - 1
            }
            onClick={(e) => e.stopPropagation() || instanceRef.current?.next()}
          >
            <IoChevronForwardOutline className="text-black" size={32} />
          </button>
        </>
      )}
    </div>
  );
};

export default CarouselHome;
