/**
 * @description Página de /checkout
 * TODO: All messages on both languages
 */

import dayjs from "dayjs";
import { useState } from "react";
import Img from "react-cool-img";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { ErrorMessage } from "@hookform/error-message";
import toast from "react-hot-toast";
import { usePayPalScriptReducer, PayPalButtons } from "@paypal/react-paypal-js";
import {
  MdOutlineAirplaneTicket,
  MdOutlineCreditCard,
  MdOutlineHome,
  MdOutlineBusAlert,
} from "react-icons/md";
import LayoutBase from "components/Layouts/Base";
import SubHeader from "components/Header/SubHeader";
import CardSummary from "components/Cards/Summary";
import Notification from "components/Notifications";
import LoaderFullScreenOpacity from "components/Loaders/FullScreenOpacity";
import { transferTypes } from "states/reducers/booking.slice";
import { delay } from "utils/delay";
import { PAYPAL_OPTIONS } from "config/paypal";
import { servicePostReservation } from "services/reservations";

// Imágenes
import ImgCards from "assets/images/cards.png";

const PageCheckout = () => {
  const { t } = useTranslation();
  const [{ isResolved }] = usePayPalScriptReducer();
  const {
    transferType,
    summary,
    adults,
    children,
    arrivalDate,
    depatureDate,
    hotel,
  } = useSelector((state) => state.booking);

  // Estados locales
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showPayments, setShowPayments] = useState(false);

  // React Hook Form
  const {
    trigger,
    control,
    register,
    getValues,
    formState: { errors: formErrors, isValid: formValid },
  } = useForm();

  const navigate = useNavigate();

  // Error de la compra
  const onPaypalError = (error) => {
    toast.custom(
      (instance) => (
        <Notification
          type="error"
          toast={toast}
          instance={instance}
          title="Payment was not possible"
          description="An unexpected error occurred, no charges were made"
        />
      ),
      { duration: 4000, position: "top-center" }
    );
    console.error("Paypal error: ", error);
  };

  // Cancelación de la compra
  const onPaypalCancel = (error) => {
    toast.custom(
      (instance) => (
        <Notification
          type="warning"
          toast={toast}
          instance={instance}
          title="Payment canceled"
          description="You have canceled the purchase"
        />
      ),
      { duration: 4000, position: "top-center" }
    );
    console.warn("Paypal cancel: ", error);
  };

  /**
   * @description Registrar la reservación
   */
  const submit = async (data) => {
    setLoading(true);
    try {
      await delay(3000);
      const { created } = await servicePostReservation(data);
      if (created) {
        // Ir a la página de confirmación
        navigate("/confirmation", {
          state: data,
        });
      }
    } catch (error) {
      console.error("Error creating: ", error);
      toast.custom(
        (instance) => (
          <Notification
            toast={toast}
            type="error"
            instance={instance}
            title={t("BOOKING-TRANSFER-CHECKOUT.ERROR5")}
            description={t("BOOKING-TRANSFER-CHECKOUT.ERROR7")}
          />
        ),
        { duration: 4000, position: "top-center" }
      );
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  };

  /**
   * @description Obtener todos los valores del formulario
   * @param { boolean } isArrival
   * @returns data: Objeto con todos los datos del formulario
   */
  const getFormData = async (isArrival = false) => {
    // Activar las validaciones de entradas del formulario
    await trigger();

    if (!formValid) {
      return null;
    }

    const formValues = getValues();

    const data = {
      isArrival,
      adultos: adults,
      menores: children,
      tipo: transferType,
      hotel: hotel?.name,
      total: summary?.price,
      idTarifa: summary?.idTarifa,
      email: formValues?.email,
      telefono: formValues?.phone,
      nombre: `${formValues?.firstName} ${formValues?.lastName}`,
      aerolinea: formValues?.airline,
      vehiculo: summary?.serviceType,
      vuelo: formValues?.flightNumber,
      fechaLlegada: "",
      horaLlegada: "",
      fechaSalida: "",
      horaSalida: "",
    };

    if (arrivalDate) {
      data.fechaLlegada = dayjs(arrivalDate).format("YYYY-MM-DD");
      data.horaLlegada = dayjs(formValues?.arrivalTime).format("HH:mm:ss");
    }

    if (depatureDate) {
      data.fechaSalida = dayjs(depatureDate).format("YYYY-MM-DD");
      data.horaSalida = dayjs(formValues?.depatureTime).format("HH:mm:ss");
    }

    return data;
  };

  /**
   * @description Submit del botón pagar on arrive
   */
  const submitArrive = async () => {
    const data = await getFormData(true);

    if (data) {
      setShowPayments(false);
      setDisabled(true);

      // Invocar a la función submit
      try {
        await submit(data);
      } catch (error) {
        console.error("Error creating: ", error);
        toast.custom(
          (instance) => (
            <Notification
              toast={toast}
              type="error"
              instance={instance}
              title={t("BOOKING-TRANSFER-CHECKOUT.ERROR5")}
              description={t("BOOKING-TRANSFER-CHECKOUT.ERROR6")}
            />
          ),
          { duration: 4000, position: "top-center" }
        );
      }
    }
  };

  /**
   * @description Submit del botón continuar con el pago
   */
  const submitPay = async () => {
    const data = await getFormData(false);

    if (data) {
      setDisabled(true);
      setShowPayments(true);
    }
  };

  return (
    <LayoutBase title="Checkout">
      <SubHeader title="CHECKOUT" />
      <div className="w-full flex justify-center bg-[#F5F4F4] py-8 lg:py-16">
        <div className="container">
          {/** Mostrar en caso de no existir data */}
          {!hotel?.id ? (
            <div className="w-full flex flex-col justify-center items-center text-center">
              <MdOutlineBusAlert
                className="text-black text-opacity-70 mb-8"
                size={64}
              />
              <span className="text-black font-semibold text-xl">
                {t("ERRORS.BOOKING-EMPTY")}
              </span>
              <Link
                to="/"
                className="bg-primary rounded text-white font-semibold py-4 px-8 flex items-center my-8"
              >
                <MdOutlineHome size={20} className="mr-2" />
                {t("ERRORS.GOTO-HOME")}
              </Link>
            </div>
          ) : (
            <div className="w-full flex flex-col lg:flex-row justify-between">
              <form className="w-full flex flex-col bg-white p-6 border-2 shadow rounded mr-8">
                <span className="font-semibold text-2xl">
                  {t("BOOKING-TRANSFER-CHECKOUT.CUSTOMER")}
                </span>
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-4">
                  <div className="flex flex-col">
                    <label
                      htmlFor="firstName"
                      className="flex flex-col my-4 col-span-1"
                    >
                      <span className="mb-2 font-medium">
                        {t("BOOKING-TRANSFER-CHECKOUT.FIRSTNAME")}*
                      </span>
                      <input
                        required
                        type="text"
                        id="firstName"
                        name="firstName"
                        disabled={disabled}
                        className="bg-gray-200 border border-gray-300 p-2 text-sm font-medium rounded-lg disabled:cursor-not-allowed"
                        {...register("firstName", {
                          required: { value: true, message: "Required field" },
                          minLength: { value: 3, message: "Min 3 characters" },
                          maxLength: {
                            value: 32,
                            message: "Max 32 characters",
                          },
                        })}
                      />
                    </label>
                    <ErrorMessage
                      errors={formErrors}
                      name="firstName"
                      render={({ message }) => (
                        <span className="text-xs text-danger font-medium">
                          {message}
                        </span>
                      )}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="lastName"
                      className="flex flex-col my-4 col-span-1"
                    >
                      <span className="mb-2 font-medium">
                        {t("BOOKING-TRANSFER-CHECKOUT.LASTNAME")}*
                      </span>
                      <input
                        required
                        type="text"
                        id="lastName"
                        name="lastName"
                        disabled={disabled}
                        className="bg-gray-200 border border-gray-300 p-2 text-sm font-medium rounded-lg disabled:cursor-not-allowed"
                        {...register("lastName", {
                          required: { value: true, message: "Required field" },
                          minLength: { value: 3, message: "Min 3 characters" },
                          maxLength: {
                            value: 32,
                            message: "Max 32 characters",
                          },
                        })}
                      />
                    </label>
                    <ErrorMessage
                      errors={formErrors}
                      name="lastName"
                      render={({ message }) => (
                        <span className="text-xs text-danger font-medium">
                          {message}
                        </span>
                      )}
                    />
                  </div>
                </div>
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-4">
                  <div className="flex flex-col">
                    <label
                      htmlFor="email"
                      className="flex flex-col my-4 col-span-1"
                    >
                      <span className="mb-2 font-medium">Email*</span>
                      <input
                        required
                        id="email"
                        type="email"
                        name="email"
                        disabled={disabled}
                        className="bg-gray-200 border border-gray-300 p-2 text-sm font-medium rounded-lg disabled:cursor-not-allowed"
                        {...register("email", {
                          required: { value: true, message: "Required field" },
                          minLength: { value: 3, message: "Min 3 characters" },
                          maxLength: {
                            value: 48,
                            message: "Max 48 characters",
                          },
                        })}
                      />
                    </label>
                    <ErrorMessage
                      errors={formErrors}
                      name="email"
                      render={({ message }) => (
                        <span className="text-xs text-danger font-medium">
                          {message}
                        </span>
                      )}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="phone"
                      className="flex flex-col my-4 col-span-1"
                    >
                      <span className="mb-2 font-medium">
                        {t("BOOKING-TRANSFER-CHECKOUT.PHONENUMBER")}*
                      </span>
                      <input
                        required
                        id="phone"
                        type="text"
                        name="phone"
                        disabled={disabled}
                        className="bg-gray-200 border border-gray-300 p-2 text-sm font-medium rounded-lg disabled:cursor-not-allowed"
                        {...register("phone", {
                          required: { value: true, message: "Required field" },
                          minLength: { value: 6, message: "Min 6 characters" },
                          maxLength: {
                            value: 16,
                            message: "Max 16 characters",
                          },
                        })}
                      />
                    </label>
                    <ErrorMessage
                      errors={formErrors}
                      name="phone"
                      render={({ message }) => (
                        <span className="text-xs text-danger font-medium">
                          {message}
                        </span>
                      )}
                    />
                  </div>
                </div>
                <div className="w-full border-t border-gray-300 my-4"></div>
                <span className="font-semibold text-2xl">
                  {t("BOOKING-TRANSFER-CHECKOUT.FLIGHT")}
                </span>
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-4">
                  <div className="flex flex-col">
                    <label
                      htmlFor="airline"
                      className="flex flex-col my-4 col-span-1"
                    >
                      <span className="mb-2 font-medium">
                        {t("BOOKING-TRANSFER-CHECKOUT.AIRLINE")}*
                      </span>
                      <input
                        required
                        type="text"
                        id="airline"
                        name="airline"
                        disabled={disabled}
                        className="bg-gray-200 border border-gray-300 p-2 text-sm font-medium rounded-lg disabled:cursor-not-allowed"
                        {...register("airline", {
                          required: { value: true, message: "Required field" },
                          minLength: { value: 3, message: "Min 3 characters" },
                          maxLength: {
                            value: 32,
                            message: "Max 32 characters",
                          },
                        })}
                      />
                    </label>
                    <ErrorMessage
                      errors={formErrors}
                      name="airline"
                      render={({ message }) => (
                        <span className="text-xs text-danger font-medium">
                          {message}
                        </span>
                      )}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="flightNumber"
                      className="flex flex-col my-4 col-span-1"
                    >
                      <span className="mb-2 font-medium">
                        {t("BOOKING-TRANSFER-CHECKOUT.FLIGHTNUMBER")}*
                      </span>
                      <input
                        required
                        type="text"
                        id="flightNumber"
                        name="flightNumber"
                        disabled={disabled}
                        className="bg-gray-200 border border-gray-300 p-2 text-sm font-medium rounded-lg disabled:cursor-not-allowed"
                        {...register("flightNumber", {
                          required: { value: true, message: "Required field" },
                          minLength: { value: 3, message: "Min 3 characters" },
                          maxLength: {
                            value: 32,
                            message: "Max 32 characters",
                          },
                        })}
                      />
                    </label>
                    <ErrorMessage
                      errors={formErrors}
                      name="flightNumber"
                      render={({ message }) => (
                        <span className="text-xs text-danger font-medium">
                          {message}
                        </span>
                      )}
                    />
                  </div>
                </div>
                {(transferType === transferTypes.round ||
                  transferType === transferTypes.airhot) && (
                  <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-4">
                    <div className="flex flex-col">
                      <label
                        htmlFor="arrivalDate"
                        className="flex flex-col my-4 col-span-1"
                      >
                        <span className="mb-2 font-medium">
                          {t("BOOKING-TRANSFER-CHECKOUT.ARRIVAL")} *
                        </span>
                        <DatePicker
                          disabled
                          selected={summary?.arrivalDate}
                          name="arrivalDate"
                          showDisabledMonthNavigation
                          placeholderText="Select date"
                          className="w-full bg-gray-200 border border-gray-300 p-2 text-sm font-medium rounded-lg text-center cursor-not-allowed placeholder:text-black"
                        />
                      </label>
                      <ErrorMessage
                        errors={formErrors}
                        name="arrivalDate"
                        render={({ message }) => (
                          <span className="text-xs text-danger font-medium">
                            {message}
                          </span>
                        )}
                      />
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor="arrivalTime"
                        className="flex flex-col my-4 col-span-1"
                      >
                        <span className="mb-2 font-medium">
                          {t("BOOKING-TRANSFER-CHECKOUT.ARRIVALTIME")}*
                        </span>
                        <Controller
                          name="arrivalTime"
                          control={control}
                          defaultValue={null}
                          rules={{
                            required: {
                              value: true,
                              message: "Required field",
                            },
                          }}
                          render={({ field: { onChange, value, ref } }) => (
                            <DatePicker
                              required
                              ref={ref}
                              showTimeSelect
                              selected={value}
                              timeIntervals={15}
                              showTimeSelectOnly
                              name="arrivalTime"
                              timeCaption="Time"
                              dateFormat="HH:mm"
                              disabled={disabled}
                              autoComplete="false"
                              onChange={onChange}
                              placeholderText="Select time"
                              className="w-full bg-gray-200 border border-gray-300 p-2 text-sm font-medium rounded-lg text-center cursor-pointer placeholder:text-black disabled:cursor-not-allowed"
                            />
                          )}
                        />
                      </label>
                      <ErrorMessage
                        errors={formErrors}
                        name="arrivalTime"
                        render={({ message }) => (
                          <span className="text-xs text-danger font-medium">
                            {message}
                          </span>
                        )}
                      />
                    </div>
                  </div>
                )}
                {(transferType === transferTypes.round ||
                  transferType === transferTypes.hotair) && (
                  <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-4">
                    <div className="flex flex-col">
                      <label
                        htmlFor="depatureDate"
                        className="flex flex-col my-4 col-span-1"
                      >
                        <span className="mb-2 font-medium">
                          {t("BOOKING-TRANSFER-CHECKOUT.DEPARTURE")} *
                        </span>
                        <DatePicker
                          disabled
                          selected={summary?.depatureDate}
                          name="depatureDate"
                          autoComplete="false"
                          showDisabledMonthNavigation
                          placeholderText="Select date"
                          className="w-full bg-gray-200 border border-gray-300 p-2 text-sm font-medium rounded-lg text-center cursor-not-allowed placeholder:text-black"
                        />
                      </label>
                      <ErrorMessage
                        errors={formErrors}
                        name="depatureDate"
                        render={({ message }) => (
                          <span className="text-xs text-danger font-medium">
                            {message}
                          </span>
                        )}
                      />
                    </div>
                    <div className="flex flex-col">
                      <label
                        htmlFor="depatureTime"
                        className="flex flex-col my-4 col-span-1"
                      >
                        <span className="mb-2 font-medium">
                          {t("BOOKING-TRANSFER-CHECKOUT.DEPARTURETIME")}*
                        </span>
                        <Controller
                          name="depatureTime"
                          control={control}
                          defaultValue={null}
                          rules={{
                            required: {
                              value: true,
                              message: "Required field",
                            },
                          }}
                          render={({ field: { onChange, value, ref } }) => (
                            <DatePicker
                              required
                              ref={ref}
                              showTimeSelect
                              selected={value}
                              timeIntervals={15}
                              showTimeSelectOnly
                              disabled={disabled}
                              name="depatureTime"
                              timeCaption="Time"
                              dateFormat="HH:mm"
                              autoComplete="false"
                              onChange={onChange}
                              placeholderText="Select time"
                              className="w-full bg-gray-200 border border-gray-300 p-2 text-sm font-medium rounded-lg text-center cursor-pointer placeholder:text-black disabled:cursor-not-allowed"
                            />
                          )}
                        />
                      </label>
                      <ErrorMessage
                        errors={formErrors}
                        name="depatureTime"
                        render={({ message }) => (
                          <span className="text-xs text-danger font-medium">
                            {message}
                          </span>
                        )}
                      />
                    </div>
                  </div>
                )}
                <div className="w-full border-t border-gray-300 my-4"></div>
                <span className="font-semibold text-2xl">
                  {t("BOOKING-TRANSFER-CHECKOUT.PAYMENT")}
                </span>
                <p className="my-2">
                  {t("BOOKING-TRANSFER-CHECKOUT.PAYMENTDESCRIPTION")}
                </p>
                <p className="mb-2">
                  {t("BOOKING-TRANSFER-CHECKOUT.TRANSACTIONS")}
                </p>
                <div className="mt-4 mb-6">
                  <Img
                    className="w-auto h-8"
                    src={ImgCards}
                    alt="Credit cards"
                  />
                </div>
                <div className="w-full flex flex-col lg:flex-row gap-2 mb-4">
                  <button
                    type="button"
                    disabled={disabled}
                    onClick={submitPay}
                    className="w-full bg-primary text-white font-semibold text-center py-4 px-6 md:px-12 2xl:px-8 rounded flex items-center justify-center h-[4.375rem] xl:mt-6 2xl:mt-0 hover:bg-secondary ease-in-out duration-200 transition-colors focus:ring uppercase disabled:cursor-not-allowed disabled:bg-opacity-50"
                  >
                    <MdOutlineCreditCard
                      aria-hidden
                      className="text-white mr-2"
                      size={18}
                    />
                    {t("BOOKING-TRANSFER-CHECKOUT.CONTINUE_PAYMENT")}
                  </button>
                  <button
                    type="button"
                    disabled={disabled}
                    onClick={submitArrive}
                    className="w-full bg-secondary text-white font-semibold text-center py-4 px-6 md:px-12 2xl:px-8 rounded flex items-center justify-center h-[4.375rem] xl:mt-6 2xl:mt-0 hover:bg-secondary ease-in-out duration-200 transition-colors focus:ring uppercase disabled:cursor-not-allowed disabled:bg-opacity-50"
                  >
                    <MdOutlineAirplaneTicket
                      aria-hidden
                      className="text-white mr-2"
                      size={18}
                    />
                    {t("BOOKING-TRANSFER-CHECKOUT.BUTTONARRIVE")}
                  </button>
                </div>
                {isResolved && showPayments && (
                  <PayPalButtons
                    className="w-full flex justify-center mt-2"
                    style={{ layout: "vertical" }}
                    createOrder={async (_data, actions) => {
                      const orderId = await actions.order.create({
                        purchase_units: [
                          {
                            amount: {
                              value: summary?.price,
                              currency_code: PAYPAL_OPTIONS.currency,
                            },
                            description: "Best Line Cancun Reservation",
                          },
                        ],
                      });
                      console.info("Paypal OrderId: ", orderId);
                      return orderId;
                    }}
                    onApprove={async (_data, actions) => {
                      const capture = await actions.order.capture();
                      if (capture?.status === "COMPLETED") {
                        const data = await getFormData(true);
                        await submit(data);
                      }
                    }}
                    onCancel={onPaypalCancel}
                    onError={onPaypalError}
                  />
                )}
              </form>
              <CardSummary hiddenButton />
            </div>
          )}
        </div>
      </div>
      {loading && (
        <LoaderFullScreenOpacity
          text={t("BOOKING-TRANSFER-CHECKOUT.GENERATING_LOADING")}
        />
      )}
    </LayoutBase>
  );
};

export default PageCheckout;
