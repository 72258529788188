/**
 * @description Componente Header
 */

import Img from "react-cool-img";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaWhatsapp, FaFacebookF } from "react-icons/fa";

// Imágenes
import ImgEN from "assets/images/usa.svg";
import ImgES from "assets/images/mx.svg";
import ImgLogo from "assets/images/logo.jpg";

const Header = () => {
  const {
    t,
    i18n: { language, changeLanguage },
  } = useTranslation();

  return (
    <header className="w-full flex flex-col">
      <div className="w-full bg-primary flex flex-row justify-center py-4">
        <div className="container flex flex-row justify-between">
          <div className="flex flex-row items-center">
            <span className="text-white text-sm font-medium mr-2 md:mr-4">
              {t("HEADER-TOP.OUR SOCIAL")}
            </span>
            <a
              href="https://www.facebook.com/Bestlinecancun/"
              target="_blank"
              rel="noreferrer"
              className="mx-2"
            >
              <span aria-label="Our Facebook">
                <FaFacebookF className="text-white" size={16} />
              </span>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=529981920397&text=I%2C%20want%20information,%20un%20quote%20or%20make%20a%20transfer%20to"
              target="_blank"
              rel="noreferrer"
              className="mx-2"
            >
              <span aria-label="Our Whatsapp">
                <FaWhatsapp className="text-white" size={16} />
              </span>
            </a>
          </div>
          <div className="flex flex-row items-center">
            <button
              className="text-sm text-white font-medium flex items-center"
              onClick={() => changeLanguage(language === "es" ? "en" : "es")}
            >
              <Img
                src={language === "es" ? ImgEN : ImgES}
                alt="Flag icon"
                className="w-4 h-5"
              />
              <span className="ml-2">
                {language === "es" ? "ENGLISH" : "ESPAÑOL"}
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-row bg-white justify-center py-6">
        <div className="container flex flex-col items-center md:flex-row md:justify-between">
          <Link to="/">
            <Img
              src={ImgLogo}
              alt="Best Line Cancún logo"
              className="w-52 h-max"
            />
          </Link>
          <div className="flex items-center gap-x-6 mt-4 md:mt-0">
            <Link
              to="/"
              className="text-black font-medium hover:text-primary focus:text-primary transition-colors duration-200 ease-in-out hover:underline hover:underline-offset-8 focus:underline focus:underline-offset-8"
            >
              {t("HEADER.HOME")}
            </Link>
            <Link
              to="/about"
              className="text-black font-medium hover:text-primary focus:text-primary transition-colors duration-200 ease-in-out hover:underline hover:underline-offset-8 focus:underline focus:underline-offset-8"
            >
              {t("HEADER.ABOUT-US")}
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
