/**
 * @description Servicio web de tarifas
 */

import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

export const servicePostRates = async (payload) => {
  try {
    const { data } = await axios.post(`${baseURL}/rates/price`, payload);
    return data?.rate;
  } catch (error) {
    throw error;
  }
};
