/**
 * @description Slice encargado de gestionar los datos de la reservación
 */

import { createSlice } from "@reduxjs/toolkit";

export const transferTypes = {
  round: 1,
  airhot: 2,
  hotair: 3,
};

const INITIAL_STATE = {
  adults: 1,
  children: 0,
  arrivalDate: null,
  depatureDate: null,
  transferType: transferTypes.round,
  hotel: { id: null, name: null, idZone: null },
  summary: {
    arrivalDate: null,
    depatureDate: null,
    serviceType: null,
    price: null,
    idTarifa: null,
  }
};

export const bookingSlice = createSlice({
  name: "booking",
  initialState: INITIAL_STATE,
  reducers: {
    changeTransferType: (state, { payload }) => {
      state.transferType = payload;
    },
    changeArrivalDate: (state, { payload }) => {
      state.arrivalDate = payload;
    },
    changeDepatureDate: (state, { payload }) => {
      state.depatureDate = payload;
    },
    changeAdults: (state, { payload }) => {
      state.adults = payload;
    },
    changeChildren: (state, { payload }) => {
      state.children = payload;
    },
    changeHotel: (state, { payload }) => {
      state.hotel = payload
    },
    changeZone: (state, { payload }) => {
      state.hotel.idZone = payload;
    },
    changeService: (state, { payload }) => {
      state.summary.serviceType = payload;
    },
    changeSummaryArrival: (state, { payload }) => {
      state.summary.arrivalDate = payload;
    },
    changeSummaryDepature: (state, { payload }) => {
      state.summary.depatureDate = payload;
    },
    changePrice: (state, { payload }) => {
      state.summary.price = payload
    },
    changeTarifa: (state, { payload }) => {
      state.summary.idTarifa = payload
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  changeTransferType,
  changeArrivalDate,
  changeDepatureDate,
  changeAdults,
  changeChildren,
  changeHotel,
  changeZone,
  changeService,
  changeSummaryArrival,
  changeSummaryDepature,
  changePrice,
  changeTarifa,
} = bookingSlice.actions;

export default bookingSlice.reducer;
