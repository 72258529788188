/**
 * @description Legals page
 */

import LayoutBase from "components/Layouts/Base";
import SubHeader from "components/Header/SubHeader";

const PageTerms = () => {
  return (
    <LayoutBase title="Terms and Conditions">
      <SubHeader title="Terms and Conditions" />
      <div className="w-full flex justify-center bg-[#F5F4F4] py-8 lg:py-16">
        <div className="container flex flex-row">
          <div className="w-full flex flex-col">
            <h2 className="text-gray-600 font-semibold text-4xl">
              Terms and Conditions
            </h2>
            <h3 className="text-gray-600 font-medium text-2xl my-4">
              Cancellations
            </h3>
            <p className="text-gray-600 font-medium">
              To cancel your reservation please email us info@bestlinecancun.com{" "}
              <br />
              50 % cancellation fee applies if the cancellation for services is
              made within 48 hours prior to the start time of the service, after
              a cancellation fee of 100 % is applied. <br />
              However, rare, bestlinecancun.com Services reserves the right to
              cancel any reservation and fully refund any deposit and cannot be
              held liable.Circumstances that can cause the cancellation of a
              reservation are as follows: <br />
              A. Clients endanger themselves or the driver, excessive
              inappropriate behaviour, verbal or physical abuse of the driver or
              other passengers, non payment, damage caused by client to any
              property of SuperShuttlecr com Services. Cancellations caused by
              any or all of the foregoing does not relieve the client from full
              payment of services. <br />
              B. A flight is cancelled. In the event your flight to Costa Rica
              is cancelled by the airline, we will not charge you for the van
              service The service would be for the next day. <br />
              C. Mechanical problems. In the event that our vehicle experiences
              mechanical problems due to breakdown or accident, the reservation
              will be cancelled by bestlinecancun.com Services and the portion
              of the service that cannot be completed will be refunded.
              SuperShutlecr.com reserves the right, in its sole discretion, to
              make alternate arrangements in the event of a mechanical problem
              or accident to complete the trip.
            </p>
            <h3 className="text-gray-600 font-medium text-2xl my-4">
              Lost & Found
            </h3>
            <div className="white-space-8"></div>
            <p className="text-gray-600 font-medium">
              Sometimes things get lost or left behind. <br />
              Our "Lost and Found" department can be reached at: + 52
              9982931705, between the hours of 8:00am and 6:00pm Central
              Standard Time, Monday to Saturday. <br />
              bestlinecancun.com Services is not responsible for lost items, but
              we will do our best to retrieve anything left behind.
            </p>
            <h3 className="text-gray-600 font-medium text-2xl my-4">
              Child seats
            </h3>
            <p className="text-gray-600 font-medium">
              The carrier does not provide child seats . Clients that bring
              their own child seats must take full responsibility for the
              fitting of the seat & the subsequent safety of the child therein.
              Clients that use child seats will be charged the full adult fare.
            </p>
            <h3 className="text-gray-600 font-medium text-2xl my-4">Animals</h3>
            <p className="text-gray-600 font-medium">
              The carrier reserves the right to class all animals as additional
              items. In the event that there is insufficient space to take any
              additional items the carrier reserves the right to refuse the
              client from travelling. A full tarif for additional items may be
              obtained from the carrier . Large animals must be caged & placed
              in the rear of the vehicle.
            </p>
            <h3 className="text-gray-600 font-medium text-2xl my-4">Smoking</h3>
            <p className="text-gray-600 font-medium">
              Clients are informed that all vehicles supplied by the carrier are
              non- smoking.
            </p>
            <h3 className="text-gray-600 font-medium text-2xl my-4">
              War & Terrorism
            </h3>
            <p className="text-gray-600 font-medium">
              The carrier is not responsible for any delays , missed flights ,
              connections and additional costs to the client incurred by acts of
              war and / or terrorism that may take place both in Cancun &
              throughout the world . During these times the carrier reserves the
              right to alter reservation confirmation pick up times as they may
              deem appropriate.
            </p>
            <h3 className="text-gray-600 font-medium text-2xl my-4">
              Strikes & demonstrations
            </h3>
            <p className="text-gray-600 font-medium">
              The carrier is not responsible for any delays , missed flights ,
              connections and additional costs to the client incurred by strikes
              , industrial action & demonstrations that may take place both in
              Cancun & throughout the world . <br />
              During these times the carrier reserves the right to alter
              reservation confirmation pick up times as they may deem
              appropriate.
            </p>
            <h3 className="text-gray-600 font-medium text-2xl my-4">
              Late arrivals
            </h3>
            <p className="text-gray-600 font-medium">
              The carrier will wait up to one hour after the scheduled arrival
              time of the carrier. After this time the carrier will attempt to
              transfer the late arrival client but undertakes no responsibility
              if this is not possible. The carrier is not liable for any
              additional costs that a late arrival may cause the client.
            </p>
            <h2 className="text-gray-600 font-medium text-2xl my-4">
              Reservation collective policy
            </h2>
            <p className="text-gray-600 font-medium">
              The waiting time is 20 to 25 minutes <br />
              Your waiting time begins to tell when you come to platform # 40{" "}
              <br />
              Fulfilling the 25 minutes the service goes to private if there is
              not a minimum of 2 passengers, if only 1 the passenger will have
              to pay the other collective so that the unit can move.
            </p>
            <h2 className="text-gray-600 font-medium text-2xl my-4">
              Reservation private policy
            </h2>
            <p className="text-gray-600 font-medium">
              The rates shown on the website are only valid at the time they are
              listed. These rates apply to the respective charge, only if the
              desired service is reserved at that time, otherwise, prices may
              vary. A reservation is considered completed, if you received your
              confirmation code and the respective charge was charged to your
              account. When booking, you authorize in writing and / or verbally
              the payment of the services according to the selected payment
              method and confirm that you accept our Terms and Conditions, as
              well as our Privacy Policy, you must print your voucher and
              present it at the time of your service ( you can also show it
              directly on your cell phone). If you wish to make a change to your
              reservation or cancel it, please contact us as soon as possible,
              indicating your confirmation code. We agree to make changes, but
              these must be approved and confirmed by our main office; Please
              note that some changes may result in a change of rate and / or
              extra cost. Best Line Cancun suggests you carefully read the
              clause corresponding to cancellation policies. Any change to an
              existing reservation is subject to availability and rate change.
              For more information, contact us, indicating your reservation
              code. All confirmed reservations provide a reservation number that
              you must keep with you at all times. On some occasions, the
              confirmation coupon may take a few minutes to reach your mail. In
              case you do not receive it and after having verified that it is
              not in your "spam" folder, please send us an email reporting the
              problem and preferably, with proof of payment made attached.
            </p>
            <h2 className="text-gray-600 font-medium text-2xl my-4">
              Transfers
            </h2>
            <p className="text-gray-600 font-medium">
              You must print your voucher and present it at the time of your
              service (you can also display it directly on your cell phone). If
              you wish to make a change to your reservation or cancel it, please
              contact us as soon as possible, indicating your confirmation code.
              We agree to make changes to dates, times and flights for arrivals
              and departures at no extra cost; To add more passengers to your
              reservation, it is mandatory to contact us prior to the date of
              service to make the request. Please consider that adding
              passengers to an existing reservation may result in an extra cost
              to the total already paid.
            </p>
            <h2 className="text-gray-600 font-medium text-2xl my-4">
              Privacy Policy
            </h2>
            <p className="text-gray-600 font-medium">
              Best Line Cancun is committed to respecting the privacy of all its
              customers and in the case of this website, to all who make
              reservations online. To ensure the security of your online
              transactions, our website has a certificate that protects the
              information that your search engine sends to our page and
              database. All information related to credit card transactions is
              encrypted, thus protecting your privacy and security. All
              information provided by clients to Best Line Cancun, such as name,
              address, email, telephone, airline, flight number and other
              information provided during the online booking process, will be
              treated with strict confidentiality. <br />
              This Privacy Notice governs the processing of personal data by
              Best Line Cancun, in the terms provided by the Federal Law on
              Protection of Personal Data Held by Private Parties and by the
              regulatory and / or administrative provisions derived or related
              to said law. <br />
              For any questions and / or questions you may have regarding the
              protection of your personal data, please contact the Best Line
              Cancun Administration area.
            </p>
          </div>
        </div>
      </div>
    </LayoutBase>
  );
};

export default PageTerms;
