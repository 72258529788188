/**
 * @description Cards de destinos
 */

import Img from "react-cool-img";

const CardDestination = ({ image, name, className }) => {
  return (
    <div className={`w-full relative h-[18rem] lg:h-[28rem] ${className}`}>
      <Img
        src={image}
        alt={name}
        className="absolute top-0 left-0 w-full h-[18rem] lg:h-[28rem] object-cover object-center"
      />
      <span className="w-full absolute bottom-0 left-0 text-white font-2xl text-center px-4 py-8 z-10 font-bold text-2xl">
        {name}
      </span>
    </div>
  );
};

export default CardDestination;
