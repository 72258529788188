/**
 * @description Componente Footer
 */

import Img from "react-cool-img";
import { Link } from "react-router-dom";
import { FaWhatsapp, FaFacebookF } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import {
  MdOutlineLocationOn,
  MdLocalPhone,
  MdOutlineEmail,
} from "react-icons/md";

// Images
import ImgLogo from "assets/images/logo.jpg";
import ImgBackground from "assets/images/footer.jpg";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer
      className="w-full flex justify-center bg-center bg-cover bg-no-repeat py-16"
      style={{ backgroundImage: `url(${ImgBackground})` }}
    >
      <div className="container flex flex-col">
        <div className="bg-white py-8 px-4 lg:px-8 rounded rounded-bl-none rounded-br-none flex flex-col lg:flex-row gap-4 xl:gap-8">
          <div className="flex flex-col items-center lg:items-start w-full lg:w-2/5">
            <Img
              src={ImgLogo}
              alt="Best Line Cancún logo"
              className="w-56 h-max"
            />
            <p className="font-medium text-black text-opacity-75 text-center lg:text-left my-6 lg:pr-8 text-sm lg:text-base">
              {t("FOOTER.DESCRIPTION")}
            </p>
            <div className="flex flex-row">
              <a
                href="https://www.facebook.com/Bestlinecancun/"
                target="_blank"
                rel="noreferrer"
                className="mx-2"
              >
                <span aria-label="Our Facebook">
                  <FaFacebookF
                    className="text-black text-opacity-60"
                    size={20}
                  />
                </span>
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=529981920397&text=I%2C%20want%20information,%20un%20quote%20or%20make%20a%20transfer%20to"
                target="_blank"
                rel="noreferrer"
                className="mx-2"
              >
                <span aria-label="Our Whatsapp">
                  <FaWhatsapp
                    className="text-black text-opacity-60"
                    size={20}
                  />
                </span>
              </a>
            </div>
          </div>
          <div className="w-full lg:w-3/5 flex flex-col items-center lg:items-start lg:flex-row gap-y-8 lg:gap-y-0">
            <div className="flex flex-col w-full lg:w-2/5 gap-y-3 text-black text-opacity-75">
              <span className="text-xl font-semibold">
                {t("FOOTER.ABOUT US")}
              </span>
              <Link
                to="/about"
                className="font-medium indent-2 text-sm lg:text-base"
              >
                - {t("FOOTER.CONTACT")}
              </Link>
              <Link
                to="/"
                className="font-medium indent-2 text-sm lg:text-base"
              >
                - {t("FOOTER.RESERVE NOW")}
              </Link>
              <Link
                to="/terms"
                className="font-medium indent-2 text-sm lg:text-base"
              >
                - {t("FOOTER.LEGAL")}
              </Link>
            </div>
            <div className="flex flex-col w-full lg:w-3/5 gap-y-3 text-black text-opacity-75">
              <span className="text-xl font-semibold">
                {t("FOOTER.ADDRESS")}
              </span>
              <Link
                to="/about"
                className="font-medium flex items-center text-sm lg:text-base"
              >
                <MdOutlineLocationOn className="mr-2" size={16} />
                SM. 237, Mza. 35, Lte. 5, Zip: 77500, Cancún, México
              </Link>
              <a
                href="tel:+52 (998) 893 3730"
                className="font-medium flex items-center text-sm lg:text-base"
              >
                <MdLocalPhone className="mr-2" size={16} />
                +52 (998) 893 3730
              </a>
              <a
                href="mailto:info@bestlinecancun.com"
                className="font-medium flex items-center text-sm lg:text-base"
              >
                <MdOutlineEmail className="mr-2" size={16} />
                info@bestlinecancun.com
              </a>
            </div>
          </div>
        </div>
        <div className="w-full bg-secondary text-white flex flex-row justify-between px-4 lg:px-8 py-4 rounded rounded-tl-none rounded-tr-none">
          <span className="text-sm">Best Line Cancún</span>
          <span className="text-sm">Cancún, México</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
