/**
 * @description Componente de tarjeta flotante de resumen
 */

import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { transferTypes } from "states/reducers/booking.slice";

const CardSummary = ({ hiddenButton = false }) => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { transferType, summary, hotel, adults, children } = useSelector(
    (state) => state.booking
  );

  return (
    <div className="w-full min-w-full lg:w-96 lg:min-w-96 flex flex-col bg-white p-6 border-2 shadow rounded self-start mt-8 lg:mt-0">
      <span className="text-center font-semibold text-2xl mb-6">
        {t("BOOKING-TRANSFER.SUMMARY")}
      </span>
      <div className="w-full flex justify-between mb-4 border-b border-b-black border-opacity-20 pb-4">
        <span className="font-medium text-sm text-black text-opacity-80">
          {t("BOOKING-TRANSFER.TRANSFER-TYPE")}:
        </span>
        <span className="font-semibold text-sm text-black text-opacity-80">
          {transferType === transferTypes.round
            ? `${t("RESERVE-FORM.ROUND-TRIP")}`
            : transferType === transferTypes.airhot
            ? `${t("RESERVE-FORM.AIRPORT-TO-HOTEL")}`
            : `${t("RESERVE-FORM.HOTEL-TO-AIRPORT")}`}
        </span>
      </div>
      {summary.arrivalDate && (
        <div className="w-full flex justify-between mb-4 border-b border-b-black border-opacity-20 pb-4">
          <span className="font-medium text-sm text-black text-opacity-80">
            {t("BOOKING-TRANSFER.ARRIVAL")}:
          </span>
          <span className="font-semibold text-sm text-black text-opacity-80">
            {dayjs(summary.arrivalDate).format("DD/MM/YYYY")}
          </span>
        </div>
      )}
      {summary.depatureDate && (
        <div className="w-full flex justify-between mb-4 border-b border-b-black border-opacity-20 pb-4">
          <span className="font-medium text-sm text-black text-opacity-80">
            {t("BOOKING-TRANSFER.DEPARTURE")}:
          </span>
          <span className="font-semibold text-sm text-black text-opacity-80">
            {dayjs(summary.depatureDate).format("DD/MM/YYYY")}
          </span>
        </div>
      )}
      <div className="w-full flex justify-between mb-4 border-b border-b-black border-opacity-20 pb-4">
        <span className="font-medium text-sm text-black text-opacity-80">
          {t("BOOKING-TRANSFER.FROM")}:
        </span>
        <span className="font-semibold text-sm text-black text-opacity-80">
          {transferType === transferTypes.hotair
            ? hotel.name
            : "Cancún International Airport"}
        </span>
      </div>
      <div className="w-full flex justify-between mb-4 border-b border-b-black border-opacity-20 pb-4">
        <span className="font-medium text-sm text-black text-opacity-80">
          {t("BOOKING-TRANSFER.TO")}:
        </span>
        <span className="font-semibold text-sm text-black text-opacity-80">
          {transferType === transferTypes.hotair
            ? "Cancún International Airport"
            : hotel.name}
        </span>
      </div>
      <div className="w-full flex justify-between mb-4 border-b border-b-black border-opacity-20 pb-4">
        <span className="font-medium text-sm text-black text-opacity-80">
          {t("BOOKING-TRANSFER.PASSAGERS")}:
        </span>
        <span className="font-semibold text-sm text-black text-opacity-80">
          {adults} {t("BOOKING-TRANSFER.ADULTS")}
          {children > 0 && `, ${children} ${t("BOOKING-TRANSFER.CHILDRENS")}`}
        </span>
      </div>
      <div className="w-full flex justify-between mb-4 border-b border-b-black border-opacity-20 pb-4">
        <span className="font-medium text-sm text-black text-opacity-80">
          {t("BOOKING-TRANSFER.SERVICE-TYPE")}:
        </span>
        <span className="font-semibold text-sm text-black text-opacity-80">
          {summary?.serviceType === 1
            ? t("BOOKING-TRANSFER.COLLECTIVE")
            : summary?.serviceType === 2
            ? t("BOOKING-TRANSFER.PRIVATE")
            : t("BOOKING-TRANSFER.VIP")}
        </span>
      </div>
      <div className="w-full flex justify-between pb-2">
        <span className="font-semibold text-2xl text-black text-opacity-80">
          Total:
        </span>
        <span className="font-bold text-2xl text-primary">
          {summary?.price ? `${summary?.price} USD` : "Pending"}
        </span>
      </div>
      {summary?.price && !hiddenButton && (
        <button
          type="button"
          className="w-full bg-primary mt-4 py-4 px-4 text-white font-semibold text-center flex items-center justify-center hover:bg-secondary ease-in-out duration-200 transition-colors focus:ring disabled:cursor-not-allowed"
          onClick={() => navigation("/checkout")}
        >
          {t("BOOKING-TRANSFER.RESERVE")}
        </button>
      )}
    </div>
  );
};

export default CardSummary;
