/**
 * @description Punto de entrada de la aplicación
 */

import { Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { PAYPAL_OPTIONS } from "config/paypal";
import SuspenseFallBack from "components/Skeletons/Suspense";
import Router from "router";

function App() {
  return (
    <div className="w-full">
      <Suspense fallback={<SuspenseFallBack />}>
        <HelmetProvider>
          <PayPalScriptProvider
            options={PAYPAL_OPTIONS}
          >
            <Router />
          </PayPalScriptProvider>
        </HelmetProvider>
        <Toaster />
      </Suspense>
    </div>
  );
}

export default App;
