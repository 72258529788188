/**
 * @description Componente de mensaje de carga con opacidad y a tamaño completo
 */

import Img from "react-cool-img";
import { CgSpinner } from "react-icons/cg";
import PropTypes from "prop-types";
import ImgLogo from "assets/images/logo.jpg";

const LoaderFullScreenOpacity = ({ text }) => {
  return (
    <div className="w-full min-h-screen flex flex-col justify-center items-center z-10 bg-white bg-opacity-90 fixed top-0 left-0">
      <Img src={ImgLogo} alt="Bestline Cancún Logo" />
      <div className="flex flex-col my-8 justify-center items-center">
        <CgSpinner size={64} className="text-primary animate-spin mb-8" />
        <span className="text-center mb-4 font-semibold text-xl text-black">
          {text}
        </span>
      </div>
    </div>
  );
};

LoaderFullScreenOpacity.propTypes = {
  text: PropTypes.string.isRequired,
};

export default LoaderFullScreenOpacity;
