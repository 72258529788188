/**
 *  @name: 404.jsx
 *  @version: 1.0.0
 *  @author: Trejocode - Sergio
 *  @description: Página de error 404
*/

import React from 'react';
import Layout from './layout';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const Page404 = () => {
    const { t } = useTranslation();
    return (
    <Layout title = "404- - Error" description = "La página no existe" keywords = "Reactjs, SEO Ready, Ejemplo" >

        <section class="flex items-center h-screen p-16 bg-gray-50 dark:bg-gray-700">
            <div class="container flex flex-col items-center ">
                <div class="flex flex-col gap-6 max-w-md text-center">
                    <h2 class="font-extrabold text-9xl text-gray-600 dark:text-gray-100">
                        <span class="sr-only">{t("ERRORS.NOTFOUND")}</span>404
                    </h2>
                    <p class="text-2xl md:text-3xl dark:text-gray-300">{t("ERRORS.NOTFOUND-MESSAGE")}</p>
                    <button class="px-8 py-4 text-xl font-semibold rounded bg-sky-500 text-gray-50 hover:text-gray-200"><Link to="/">{t("ERRORS.NOTFOUND-ACTION")}</Link></button>
                </div>
            </div>
        </section>
        
    </Layout>
    );
};

export default Page404;