/**
 * @description Layout base de las páginas, preparada para SEO.
 */

import { Fragment } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import Header from "components/Header";
import Footer from "components/Footer";

const LayoutBase = ({ title, description, keywords, children }) => {
  return (
    <Fragment>
      <Helmet>
        <title>
          {title
            ? title + " - Best Line Cancún"
            : "Best Line Cancún - Cancún Airport Shuttle and Cab Private Service"}
        </title>
        <meta
          name="description"
          content={
            description ||
            "Book now private transportation from the airport to the hotel zone, playa del carmen, tulum from 30"
          }
        />
        <meta
          name="keywords"
          content={
            keywords ||
            "Cancún Airport Shuttle, Cancún Airport Cab, Cancún Shuttle, Cancún Airport Transportation"
          }
        />
      </Helmet>
      <Header />
      {children}
      <Footer />
    </Fragment>
  );
};

LayoutBase.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default LayoutBase;
