/**
 * @description: Configuración inicial del módulo de internalización (i18n)
 */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const fallbackLng = ["es"];
const availableLanguages = ["es", "en"];

const DEBUG = process.env.REACT_APP_I18N_DEBUG || false;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng,
    debug: DEBUG,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
